import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isMacOs,
  isMobileSafari,
  isSafari,
} from "react-device-detect";

const LINK_APPLE =
  "https://apps.apple.com/app/buzzly-ugc-collabs-perks/id6443504978";
const LINK_ANDROID =
  "https://play.google.com/store/apps/details?id=com.heyflup.flup";

function App() {
  let urlRedirect;
  if (
    isIOS ||
    isSafari ||
    isMobileSafari ||
    isIOS13 ||
    isIPhone13 ||
    isIPad13 ||
    isIPod13 ||
    isMacOs
  ) {
    urlRedirect = LINK_APPLE;
  } else {
    urlRedirect = LINK_ANDROID;
  }
  window.location.replace(urlRedirect);
  return <div className="App"></div>;
}

export default App;
